<template>
    <el-dialog :close-on-click-modal="false" title="医废编码详情" :visible.sync="show" width="850px" class="selfInputBox selfInputBoxIV">

        <div class="searchBox" style="">

            <!-- <label for="">编码号段</label>
            <el-select v-model="valueSEL" placeholder="请选择字典类型">
                <el-option v-for="item in options" :key="item.valueKEY" :label="item.label" :value="item.valueKEY">
                </el-option>
            </el-select>

            <label for="">编码状态</label>
            <el-select v-model="valueSEL" placeholder="请选择字典类型">
                <el-option v-for="item in options" :key="item.valueKEY" :label="item.label" :value="item.valueKEY">
                </el-option>
            </el-select>

            <el-button type="primary" @click="refresh">
                <i class="el-icon-search" aria-hidden="true"></i>
                查询
            </el-button> -->

            <!-- <label for="">编码号段</label>
            <el-input style="width: 350px;" :value="list.code_start+' ~ '+list.code_end" disabled></el-input> -->


            <label for="">编码号段</label>
            <el-select style="width: 320px;margin-right: 30px;" v-model="numberstatus" placeholder="请选择编码号段"
                class="SelfSelect">
                <!-- <el-option v-for="(item,index) in segment" :key="item.id" :label="CoveringO(item)" :value="item" /> -->
                <el-option v-for="(item,index) in segment" :key="item.id" :label="CoveringO(item)" :value="item.id" />
            </el-select>



            <label for="">状态</label>
            <el-select style="width: 140px;margin-right: 30px;" v-model="status" placeholder="请选择使用状态"
                class="SelfSelect">
                <el-option :label="'全部'" :value="''"></el-option>
                <el-option :label="'未使用'" :value="'0'"></el-option>
                <el-option :label="'使用过'" :value="'1'"></el-option>
            </el-select>


            <el-button type="primary" icon="el-icon-search" @click="refresh">查询</el-button>

            <!-- <el-form-item label="所属医院"> -->
            <!-- <label for="">所属医院</label>
            <el-select style="width: 240px;" disabled v-model="list.dep_id" placeholder="请选择所属医院" class="SelfSelect">
                <el-option :label="item.name" :value="item.id" v-for="item in depList" :key="item.id"></el-option>
            </el-select> -->
            <!-- </el-form-item> -->

        </div>


        <!-- <div class="numCellBox">
            <div class="numCell" v-for="(item,index) in 20" :key="index" :class="{'sel':item==clickSelect}"
                @click="clickSelect=item">
                YF{{Math.pow(Math.round(Math.random() * 1000),3)}}
            </div>
        </div> -->

        <div class="numCellBox">
            <div class="numCell" v-for="(item,index) in allCode" :key="index" :class="{'sel':item.state==1}">
                {{item.code}}
            </div>
        </div>


        <!-- <div style="height: 70px;">
            <el-pagination layout="total, prev, pager, next" background :current-page.sync="data.page"
                :page-size="data.size" :total="data.total" @change="loadData()" @size-change="getList" />
        </div> -->

        <!-- <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="refresh">保存</el-button>
                <el-button @click="$refs.form.resetFields()">重置</el-button>
            </div>
        </span> -->

    </el-dialog>
</template>

<script>
    import {
        mapState,
        mapMutations
    } from 'vuex'

    export default {

        // model: {
        //     prop: 'value',
        //     event: 'change'
        // },
        // props: {
        //     value: {
        //         type: Object,
        //         required: true
        //     }
        // },

        data() {
            return {
                show: false,
                formShow: false,

                list: {},
                allCode: [],

                status: "",

                numberstatus: "",

                depList: [],

                clickSelect: null

            }
        },

        computed: {
            ...mapState('m_item', ['LevelList', 'PositionList', 'DepList', 'HealthList', 'Province', 'City', 'District',
                'RoleList', 'Depcate', 'ItemType'
            ]),

            segment() {

                if (this.list.code) {
                    var a = this.splitArray(this.list.code, 50)
                    return a

                } else {
                    return []

                }

            },
        },

        watch: {
            show(value) {
                this.status = ""
                if (this.show) {


                    if (this.depList.length == 0) {
                        this.getdep()
                        setTimeout(() => {
                            this.depList = this.DepList
                        }, 1000)
                    }

                } else {


                }
            },

            status(value) {

                var a = this.segment.filter(x => {
                    return x.id == this.numberstatus
                })[0].array

                var b = a.filter(x => {
                    if (value) {
                        return x.state == value
                    } else {
                        return true
                    }
                })

                this.allCode = b

            },

            numberstatus(value) {

                var a = this.segment.filter(x => {
                    return x.id == value
                })[0].array
                this.allCode = a

            },

            segment(value) {
                this.numberstatus = this.segment[0].id
            }

        },

        methods: {

            splitArray(arr, size) {

                let aaa = [];
                for (let i = 0; i < arr.length; i += size) {
                    aaa.push({
                        id: arr[i].id,
                        array: arr.slice(i, i + size)
                    })
                }
                return aaa

            },

            CoveringO(val) {

                var a = val.array[0].code
                var b = val.array[val.array.length - 1].code
                return a + "~" + b

            },

            getList() {

            },
            loadData() {

            },

            open(row) {
                this.formShow = true
                this.show = true

                this.$http.get('/api/qrcode/' + row.id).then((res) => {

                    if (res.code == 200) {
                        this.list = res.data

                        this.allCode = this.list.code

                        // this.data.list = res.data
                        // this.data.total = res.data.total
                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }
                }).catch((err) => {
                    console.log(err)
                });

            },

            refresh() {
                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },

        }
    }
</script>

<style scoped>
    .searchBox {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        /* border: 1px red solid; */
        box-sizing: border-box;
        margin-bottom: 10px;
    }

    .searchBox Label {
        height: 36px;
        line-height: 36px;
    }

    .searchBox .el-select.el-select--mini {
        width: 200px;
    }


    /deep/.el-dialog__body {
        /* border: 1px solid #fff0;
        box-sizing: bo rder-box;
        border: 1px red solid;
        box-sizing: border-box; */
        padding: 20px;
    }


    .numCellBox {

        padding: 20px 0;
        margin-bottom: 10px;
        height: 400px;
        overflow-y: auto;

        /* display: flex;
        justify-content: flex-start;
        flex-wrap: wrap; 
        flex-direction: row; */
    }

    .numCellBox .numCell {
        border: 1px red solid;
        box-sizing: border-box;
        margin: 0 0 10px 0;
        padding: 5px;

        float: left;

        width: calc((100% - 30px) / 4);
        margin-right: 10px;
        text-align: center;

        background: #EEFFF6;
        border: 1px solid #5BD995;
        border-radius: 2px;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #656565;
    }

    .numCellBox .numCell:nth-of-type(4n) {
        margin-right: 0px;
    }

    .numCellBox .numCell.sel {
        background: #FFEDE8;
        border: 1px solid #FF754C;
    }
</style>